const URL_API = 'https://www.solution-apoio.pt/api/contact-request'


export const addContactRequest = (contactRequest) => {
    return new Promise((resolve, reject) => {
        fetch(`${URL_API}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactRequest)
        })
        .then(response => response.json())
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
};
