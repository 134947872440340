import React, { useEffect } from "react"
import '../../assets/styles/global.css';
import './styles.css';
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useTranslation } from 'react-i18next';
import imgAboutUs from '../../assets/images/img-about-us.jpg'
import campanhe from '../../assets/images/campanhe.jpg'
import jumara from "../../assets/images/logo-javascript.png"
import AOS from 'aos';
import 'aos/dist/aos.css';


function About_Us(){
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({
          duration: 1000, // Define a duração da animação
        });
      }, []);


    return(
        <div>
            <Header pageName={t('home')} headerName={t('who_we_are')} headerText={t('home')} headerStyle="header-60" buttonName ={t('contact_us')} buttonLink="/contact"/>
                
            <section>
                <aside className="who-we-are" data-aos="fade-down">
                    <h3>{t('about_us')}</h3>
                    <img src={imgAboutUs} className="img-campanhe"  />
                </aside>
                <aside className="history">
                    <h3 data-aos="fade-down">{t('who_we_are')}</h3>
                    <article>
                        <img src={campanhe} style={{width: '400px'}} data-aos="fade-right"/>
                        <p data-aos="fade-left">{t('who_we_are_text')}</p>
                    </article>
                </aside>

                <aside className="mission-vision-value" data-aos="fade-left">
                    <div>
                        <article>
                            <h3>{t('our_mission')}</h3>
                            <p> Ser reconhecida como a principal parceira de negócios para pequenas e médias empresas, ajudando-as a alcançar seus objetivos através de nossos serviços de alta qualidade.</p>
                        </article>
                        <hr />
                        <article>
                            <h3>{t('our_mission')}</h3>
                            <p> Fornecer soluções de negócios inovadoras e personalizadas que impulsionem o crescimento e o sucesso de nossos clientes</p>
                        </article>
                        <hr />
                        <article>
                            <h3>{t('our_mission')}</h3>
                            <p> Fornecer soluções de negócios inovadoras e personalizadas que impulsionem o crescimento e o sucesso de nossos clientes</p>
                        </article>
                    </div>
                </aside>
                <aside data-aos="fade-down">
                    <h3>{t('our_mission')}</h3>
                    <article className="article-card">
                        <div className="card">
                            <div className="card-title">
                                <h4>PEquenas empresas</h4>
                            </div>
                            <div className="card-header">
                                <img src={jumara} />
                            </div>
                            <div className="card-text">
                                <p>Presidente e CEO</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title">
                                <h4>PEquenas empresas</h4>
                            </div>
                            <div className="card-header">
                                <img src={jumara} />
                            </div>
                            <div className="card-text">
                                <p>Presidente e CEO</p>
                            </div>
                        </div>
                    </article>
                </aside>
            </section>

            <Footer />
        </div>
    )
}

export default About_Us