import React, {useEffect} from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import website from '../../assets/images/website-example.png';
import app from '../../assets/images/app-example.jpg';
import db from '../../assets/images/database-example.png'
import man_call from '../../assets/images/man-call.jpg';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './styles.css'; 
import '../../assets/styles/global.css'

function Home() {

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({
          duration: 1000, // Define a duração da animação
        });
      }, []);

    return (
        <div className="App">
            <Header pageName={t('home')} headerName={t('who_we_are')} headerText={t('home')} headerStyle="header-90" buttonName ={t('contact_us')} buttonLink="/contact"/>
            
            <section>
                <aside data-aos="fade-down">
                    <h3>{t("welcome")}</h3>
                    <p>Somos uma empresa x....</p>
                </aside>
                <aside className='bg-image' data-aos="fade-down">
                    <article className='itens-center'>
                        <h3>Somos especialista nas vossas necessidades</h3>
                        <p>A solution apoio vós oferece ...</p>
                        <a className='button bt-primary'>Saber mais</a>

                    </article>
                </aside>

                <aside className='aside-services'>
                    <article data-aos="fade-right">
                        <img src={website} />
                    </article>
                    <article className='itens-center' data-aos="fade-left">
                        <h3>Vamos ajudar teu negocii</h3>
                        <p>dwsndbisndsk</p>
                        <a className='button bt-secondary'>Saber mais</a>
                    </article>
                </aside>
                
                <aside className='aside-services background-primary text-white'>
                    <article className='aside-image' data-aos="fade-right">
                        <img src={app} />
                    </article>
                    <article className='itens-center' data-aos="fade-left">
                        <h3>Vamos ajudar teu negocii</h3>
                        <p>dwsndbisndsk</p>
                        <a className='button bt-primary'>Saber mais</a>
                    </article>
                </aside>

                <aside className='aside-services'>
                    <article data-aos="fade-right">
                        <img src={db} />
                    </article>
                    <article className='itens-center' data-aos="fade-left">
                        <h3>Vamos ajudar teu negocii</h3>
                        <p>dwsndbisndsk</p>
                        <a className='button bt-secondary'>Saber mais</a>
                    </article>
                </aside>

                <aside className='aside-call-man background-primary text-white'>
                    <article className='aside-image' data-aos="fade-right">
                        <img src={man_call} />
                    </article>
                    <article className='itens-center' data-aos="fade-left">
                        <h3>Precisas de crescer tecnologicamente, nós contacta e ganhas mais....</h3>   
                        <p>Um website é uma plataforma digital que possibilita a divulgação de informações, produtos ou serviços na internet. Por meio de um site, indivíduos, empresas e organizações podem alcançar um público global, independentemente da localização geográfica</p>
                        <a className='button bt-primary'>Saber mais</a>
                    </article>
                </aside>

                <aside className='itens-center' data-aos="fade-down">
                    <h3>Precisas de crescer tecnologia, nos conra
                    e ganhas muito mais...</h3>
                    <iframe src="https://www.youtube.com/embed/pBPoPhBz_Yo?si=oGbZ9CFkRaZb3m1X"></iframe>
                </aside>
            </section>

            <Footer data-aos="fade-down" />
        </div>
    );
}

export default Home;
