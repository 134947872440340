import React, { useEffect } from "react";
import Footer from "../../components/footer";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import phoneImage from '../../assets/images/app-service-example.jpg'; // Caminho para a imagem do telefone
import './styles.css'; // Estilos personalizados para este componente
import LogoCarousel from "../../components/logo-carrosel";

import androidStudioLogo from '../../assets/images/logo-android-studio.png';
import mongoLogo from '../../assets/images/logo-mongodb.png';
import fastAPILogo from '../../assets/images/logo-fastapi.png';
import firebaseLogo from '../../assets/images/logo-firebase.png';
import javascriptLogo from '../../assets/images/logo-javascript.png';
import mysqlLogo from '../../assets/images/logo-mysql.png';
import phpLogo from '../../assets/images/logo-php.png';
import sqlServerLogo from '../../assets/images/logo-sql-server.png';
import reactLogo from '../../assets/images/logo-reactjs.png';

function Services() {
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const logos = [
        { src: androidStudioLogo, alt: 'React Logo' },
        { src: mongoLogo, alt: 'React Logo' },
        { src: fastAPILogo, alt: 'React Logo' },
        { src: firebaseLogo, alt: 'React Logo' },
        { src: javascriptLogo, alt: 'React Logo' },
        { src: mysqlLogo, alt: 'React Logo' },
        { src: phpLogo, alt: 'React Logo' },
        { src: sqlServerLogo, alt: 'React Logo' },
        { src: reactLogo, alt: 'React Logo' },
        // Adicione outros logos aqui
    ];

    return (
        <div>
            <Header pageName={t('services')} headerName={t('Services')} headerText={t('services')} headerStyle="header-60" buttonName={t('join_team')} buttonLink="/home" />
            <section>
                <aside>
                    <h3>{t('services')}</h3>
                    <div className="services-section">
                        <aside>
                            <div className="services-list">
                                <div className="service-item" data-aos="fade-right">
                                    <div className="service-number">1</div>
                                    <div className="service-text">{t('application')}</div>
                                </div>
                                <div className="service-item" data-aos="fade-right">
                                    <div className="service-number">2</div>
                                    <div className="service-text">{t('database')}</div>
                                </div>
                                <div className="service-item" data-aos="fade-right">
                                    <div className="service-number">3</div>
                                    <div className="service-text">{t('websites')}</div>
                                </div>
                                <div className="service-item" data-aos="fade-right">
                                    <div className="service-number">4</div>
                                    <div className="service-text">{t('consulting')}</div>
                                </div>
                                <div className="service-item" data-aos="fade-right">
                                    <div className="service-number">5</div>
                                    <div className="service-text">{t('maintenance')}</div>
                                </div>
                                <div className="service-item" data-aos="fade-right">
                                    <div className="service-number">6</div>
                                    <div className="service-text">{t('security')}</div>
                                </div>
                            </div>
                        </aside>
                        <aside className="phone-image" data-aos="fade-left">
                            <img src={phoneImage} alt="Phone" />
                        </aside>
                    </div>
                </aside>
                
                <aside data-aos="fade-down">
                    <LogoCarousel logos={logos} />
                </aside>
            </section>
            <Footer />
        </div>
    );
}

export default Services;
