import React from 'react';
import './styles.css';
import androidStudioLogo from '../../assets/images/logo-android-studio.png';
import mongoLogo from '../../assets/images/logo-mongodb.png';
import fastAPILogo from '../../assets/images/logo-fastapi.png';
import firebaseLogo from '../../assets/images/logo-firebase.png';
import javascriptLogo from '../../assets/images/logo-javascript.png';
import mysqlLogo from '../../assets/images/logo-mysql.png';
import phpLogo from '../../assets/images/logo-php.png';
import sqlServerLogo from '../../assets/images/logo-sql-server.png';
import reactLogo from '../../assets/images/logo-reactjs.png';


function LogoCarousel({logos}) {
    return (
        <div className="logo-carousel">
            <h2>Nossas experiencias</h2>
            <div className="logo-track">
                {logos.map((logo, index) => (
                    <div className="logo-item" key={index}>
                        <img src={logo.src} alt={logo.alt} />
                    </div>
                ))}
                {/* Duplicate logos to create a seamless loop */}
                {logos.map((logo, index) => (
                    <div className="logo-item" key={index + logos.length}>
                        <img src={logo.src} alt={logo.alt} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LogoCarousel;
