
import React from "react";

function ServiceDetails() {
    return(
        <div className="container">
            <aside>
                <h2>O que é websites?</h2>
                <p>Um website é uma aplicação de software que pode ser acessada via internet,</p>
            </aside>
            <aside>
                <h2>O que inaginas, nós criamos</h2>
                <p>Um website é uma aplicação de software que pode ser acessada via internet,</p>
                <article>
                    <p>Somos uma empresa.....</p>
                    <img src={""}/>
                </article>
            </aside>
        </div>
    )
}

export default ServiceDetails;