import React from "react";
import './styles.css'

function Stage() {
    return(
        <div>
            <div className="aside-stage">
                <div className="stage"> 
                    <div className="card-stage">
                        <div className="circle itens-center">
                            <p>Discussões técnicas para definir cenários de teste</p>
                        </div>
                        <div className="triangle">
                            <span className="vertex-1"></span>
                            <span className="vertex-2"></span>
                            <span className="vertex-3"></span>
                        </div>
                    </div>
                    <div className="card-stage">
                        <div className="circle itens-center">
                            <p>Discussões técnicas para definir cenários de teste</p>
                        </div>
                        <div className="triangle">
                            <span className="vertex-1"></span>
                            <span className="vertex-2"></span>
                            <span className="vertex-3"></span>
                        </div>
                    </div>
                    <div className="card-stage">
                        <div className="circle itens-center">
                            <p>Discussões técnicas para definir cenários de teste</p>
                        </div>
                        <div className="triangle">
                            <span className="vertex-1"></span>
                            <span className="vertex-2"></span>
                            <span className="vertex-3"></span>
                        </div>
                    </div>
                    <div className="card-stage">
                        <div className="circle itens-center">
                            <p>Discussões técnicas para definir cenários de teste</p>
                        </div>
                        <div className="triangle">
                            <span className="vertex-1"></span>
                            <span className="vertex-2"></span>
                            <span className="vertex-3"></span>
                        </div>
                    </div>
                </div>
                
                <div>
                    <hr className="line" />
                </div>
            </div>
        </div>
    )
}

export default Stage