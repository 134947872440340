import React from "react";
import Footer from "../../components/footer";
import { useTranslation } from "react-i18next";
import Header from "../../components/header";
import '../../assets/styles/global.css';
import './styles.css';
import Card from "../../components/card";
import img from "../../assets/images/logo-javascript.png"
import RequestForm from "../../components/RequestForm";
import LogoCarousel from "../../components/logo-carrosel";
import Stage from "../../components/stage";
import appExample from "../../assets/images/app-example.jpg";
import androidStudioLogo from '../../assets/images/logo-android-studio.png';
import mongoLogo from '../../assets/images/logo-mongodb.png';
import fastAPILogo from '../../assets/images/logo-fastapi.png';
import firebaseLogo from '../../assets/images/logo-firebase.png';
import javascriptLogo from '../../assets/images/logo-javascript.png';
import mysqlLogo from '../../assets/images/logo-mysql.png';
import phpLogo from '../../assets/images/logo-php.png';
import sqlServerLogo from '../../assets/images/logo-sql-server.png';
import reactLogo from '../../assets/images/logo-reactjs.png';
import ServiceDetails from "../../components/service-details";
import '../../assets/styles/global.css'

function Application() {
    const {t} = useTranslation();

    const typesApp = [
        {headerText: '', img: img, altImg: 'Foto aplicação', textTitle: 'Aplicação android', text: 'compativel com dispositivos android', imgStyle: {borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}},
        {headerText: '', img: img, altImg: 'Foto aplicação', textTitle: 'Aplicação iOS', text: 'compativel com dispositivos iOS', imgStyle: {borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}},
        {headerText: '', img: img, altImg: 'Foto aplicação', textTitle: 'Aplicação multiplataforma', text: 'compativel com dispositivos iOS e android', imgStyle: {borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}
    ]

    const logos = [
        { src: androidStudioLogo, alt: 'React Logo' },
        { src: mongoLogo, alt: 'React Logo' },
        { src: fastAPILogo, alt: 'React Logo' },
        { src: firebaseLogo, alt: 'React Logo' },
        { src: javascriptLogo, alt: 'React Logo' },
        { src: mysqlLogo, alt: 'React Logo' },
        { src: phpLogo, alt: 'React Logo' },
        { src: sqlServerLogo, alt: 'React Logo' },
        { src: reactLogo, alt: 'React Logo' },
        // Adicione outros logos aqui
    ];
    return(
        <div>
            <Header pageName={t('home')} headerName={t('who_we_are')} headerText={t('home')} headerStyle="header-60" buttonName ={t('contact_us')} buttonLink="/contact"/>
            
            <section>
                <ServiceDetails />
            </section>
            <section>
                <aside className="background-primary padding-50 text-white">
                    <h2>Criação a aplicação que necessitas</h2>
                    <article className="display-flex">
                        <div>
                            <img src={appExample} />
                        </div>
                        <div className="itens-center">
                            <p>Criamos a aplicação....</p>
                            <a className="button bt-primary">Falar com especialista</a>
                        </div>
                    </article>
                </aside>
                <aside>
                    <Card cardTitle={"Tipos de aplicações móveis"} typesApp={typesApp} />
                </aside>
                <aside>
                    <LogoCarousel logos={logos}  />
                </aside>
                <aside>
                    <RequestForm />
                </aside>
                <aside>
                    <Stage />
                </aside>
            </section>

            <Footer />
        </div>
    )
}

export default Application;