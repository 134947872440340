import Aos from "aos";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/header";
import Footer from "../../components/footer";
import LogoCarousel from "../../components/logo-carrosel";
import RequestForm from "../../components/RequestForm";
import Stage from "../../components/stage";
import '../../assets/styles/global.css'
import appExample from "../../assets/images/app-example.jpg";
import androidStudioLogo from '../../assets/images/logo-android-studio.png';
import mongoLogo from '../../assets/images/logo-mongodb.png';
import fastAPILogo from '../../assets/images/logo-fastapi.png';
import firebaseLogo from '../../assets/images/logo-firebase.png';
import javascriptLogo from '../../assets/images/logo-javascript.png';
import mysqlLogo from '../../assets/images/logo-mysql.png';
import phpLogo from '../../assets/images/logo-php.png';
import sqlServerLogo from '../../assets/images/logo-sql-server.png';
import reactLogo from '../../assets/images/logo-reactjs.png';
import ServiceDetails from "../../components/service-details";


function Websites() {

    const {t} = useTranslation();

    const logos = [
        { src: androidStudioLogo, alt: 'React Logo' },
        { src: mongoLogo, alt: 'React Logo' },
        { src: fastAPILogo, alt: 'React Logo' },
        { src: firebaseLogo, alt: 'React Logo' },
        { src: javascriptLogo, alt: 'React Logo' },
        { src: mysqlLogo, alt: 'React Logo' },
        { src: phpLogo, alt: 'React Logo' },
        { src: sqlServerLogo, alt: 'React Logo' },
        { src: reactLogo, alt: 'React Logo' },
    ];
    

    useEffect(() => {
        Aos.init({
          duration: 1000, // Define a duração da animação
        });
      }, []);

    return(
        <div>
            <Header pageName={t('home')} headerName={t('who_we_are')} headerText={t('home')} headerStyle="header-60" buttonName ={t('contact_us')} buttonLink="/contact"/>
            <section>
                <ServiceDetails />
            </section>
            <section>
                <LogoCarousel logos={logos}  />
            </section>

            <section>
                <RequestForm />
            </section>
            
            <section>
                <Stage />
            </section>

            <Footer />
        </div>
    )
}

export default Websites;