import React, { useEffect } from "react";
import Header from "../../components/header";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer";

import img_mirandela from '../../assets/images/logo-javascript.png';
import '../../assets/styles/global.css';
import './styles.css';
import RequestForm from "../../components/RequestForm";
import AOS from 'aos';
import 'aos/dist/aos.css';

function ContactUs(){
    const {t} = useTranslation();

    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);

    return(
        <div>
            <Header pageName={t('home')} headerName={t('who_we_are')} headerText={t('home')} headerStyle="header-60" buttonName ={t('contact_us')} buttonLink="/contact"/>

            <section>
                <aside className="aside-contact" data-aos="fade-down">
                    <h3>Onde pode nos contactar?</h3>
                    <p>POderá nos contacto utilizando o email ou através do número de telemóvel. Ou ainda pelo formulario</p>
                    <article className="article-contact">
                        <div>
                            <img src={img_mirandela} />
                        </div>
                        <div className="card-contact">
                            <h4>Mirandela, Portugal</h4>
                            <ul>
                                <li><a><i class="bi bi-telephone-outbound-fill"></i> (+351) 920040232</a></li>
                                <li><a><i class="bi bi-envelope-at"></i> geral@solution-apoio.pt</a></li>
                                <li><a><i class="bi bi-envelope-at"></i> info@solution-apoio.pt</a></li>
                                <li><a><i class="bi bi-globe2"></i> www.solution-apoio.pt</a></li>
                            </ul>
                        </div>
                    </article>
                </aside>
                
                <RequestForm />

                <aside data-aos="fade-left">
                    <h3>Presença digital</h3>
                    <p>Poderá nos encontras nas redes socais</p>
                    <div className="card-social-media">
                        <span><i class="bi bi-facebook"></i></span>
                        <span><i class="bi bi-whatsapp"></i></span>
                        <span><i class="bi bi-instagram"></i></span>
                        <span><i class="bi bi-youtube"></i></span>
                        <span><i class="bi bi-linkedin"></i></span>
                    </div>
                </aside>
            </section>

            <Footer />
        </div>
    )
}

export default ContactUs