import React from "react";
import logo from '../../assets/images/logo.jpg';
import './styles.css';


function Footer() {
    return(
        <footer>
            <div className="footer">
                <aside>
                    <img src={logo} />
                    <p>Ajudamos você a ter um website e aplicação online para o seu negócio 👨‍💻</p>
                </aside>
                <aside>
                    <h3>Services</h3>
                    <ul>
                        <li><a>Websites</a></li>
                        <li><a>Aplicações móveis</a></li>
                        <li><a>Base de dados</a></li>
                        <li><a>Consultadoria em tecnologia</a></li>
                        <li><a>Manutenção e suporte técnico</a></li>
                        <li><a>Segurança cibernética</a></li>
                    </ul>
                </aside>
                <aside>
                    <h3>Encontra-nos</h3>
                    <ul className="contact">
                        <li><a><i class="bi bi-telephone-outbound-fill"></i> (+351) 920040232</a></li>
                        <li><a><i class="bi bi-envelope-at"></i> geral@solution-apoio.pt</a></li>
                    </ul>
                    <ul className="social-media">
                        <li><a><i class="bi bi-facebook"></i></a></li>
                        <li><a><i class="bi bi-youtube"></i></a></li>
                        <li><a><i class="bi bi-linkedin"></i></a></li>
                        <li><a><i class="bi bi-instagram"></i></a></li>
                        <li><a><i class="bi bi-github"></i></a></li>
                    </ul>
                </aside>
            </div>
            <div className="copyright">
                <p>2024 &copy; Solution Apoio</p>
            </div>
        </footer>
    )    
}

export default Footer;